table {
  margin: 24px 0px;
  box-shadow: 0 2px 2px rgba($black, 0.24), 0 0 2px rgba($black, 0.12);
  border-radius: 2px;
  background: $offwhite;

  &.is-full-width {
    width: 100%;
  }

  &.is-fixed-layout {
    table-layout: fixed;
  }

  thead > {
    vertical-align: middle;
    border-color: inherit;

    tr {
      vertical-align: inherit;
      border-color: inherit;
    }

    tr > th {
      background: rgba($lightgray, 0.2);
      border-bottom: 1px solid $lightgray;
      color: $darkgray;
      font-size: 12px;
      font-weight: 500;
      padding: 8px 24px;
      text-align: left;
      text-transform: uppercase;
      line-height: 28px;
    }
  }

  tbody > tr > {
    th,
    td {
      border-bottom: 1px solid $lightgray;
      padding: 16px;
      text-align: left;
      line-height: 24px;
      vertical-align: top;

      @media (max-width: 480px) {
        &:before {
          // content: **ADD TABLE HEADER**;
          display: inline-block;
        }
      }

    }

    td {
      letter-spacing: 0.30px;

      tr td:first-child {
        @media (max-width: 480px) {
          background-color: $lightgray;
        }
      }
    }

    th {
      background: rgba($lightgray, 0.2);
      border-right: 1px solid $lightgray;
      font-weight: 600;
      max-width: 100px;
    }
  }

  tbody > tr {
    &:last-child td {
      border: none;

      @media (max-width: 480px) {
        border-bottom: 1px solid $lightgray;
      }
    }
  }
}

#cheatsheet {

  table tbody td {
    overflow: auto;
  }

  @media only screen and (max-width: 990px) {

    /* Force table to not be like tables anymore */
    table, thead, tbody, tfoot, tr, th, td {
      display: block;
      position: relative;
      max-width: 100%;

      code {
        padding: 0;
        background-color: inherit;
      }
    }

    th {
      border-right: none;
    }

    th, td {
      &:not(:last-child) {
        border-bottom: none;
        padding-bottom: 0px;
      }
    }
  }
}